<template>
  <div>
    <sm-editable-list
      ref="editableList"
      :isLoading="isLoadingPage"
      :controllerName="controllerName"
      :parentName="parentName"
      :breadcrumbs="breadcrumbs"
      :tableCaption="tableCaption"
      :tableHeaders="tableHeaders"
      showEditButton
      @edit="onEdit"
    >
      <template #top-panel>
        <sm-button class="add-client-button" @click="onOpenAddClientModal">
          Подключить клиента
        </sm-button>
      </template>
      <template v-slot:deleteClientButton="{ row }">
        <sm-button
          class="remove-client-button"
          @click="onOpenDeleteClientModal(row.id)"
        >
          Отключить клиента
        </sm-button>
      </template>
    </sm-editable-list>
    <sm-form-modal
      v-model="modalForm"
      :fields="modalFields"
      :show="showAddClientModal"
      :modal-title="modalAddClientTitle"
      :disabledSaveButton="isEqualAddClient"
      :isLoadingSaveButton="isLoadingAddClient"
      @close="onCancelAddClient"
      @save="onConfirmAddClient"
      @cancel="onCancelAddClient"
    />
  </div>
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';
import SmFormModal from '@/components/common/modals/SmFormModal.vue';
// api
import customersApi from '@/api/customers.js';

export default {
  name: 'CustomersForAccount',

  components: {
    SmEditableList,
    SmButton,
    SmFormModal,
  },

  customersApi,

  mixins: [editableList],

  data() {
    return {
      controllerName: 'Customers',
      parentName: 'Account',
      tableCaption: 'Связанные клиенты',      
      tableHeaders: [
        { text: 'ID', alias: 'id', order: 'id' },
        { text: 'Наименование', alias: 'name', order: 'name' },
        { text: 'Разрешен вход на help.1caero.ru', alias: 'allowHelpAeroAccess', order: 'allowHelpAeroAccess' },        
        { alias: 'deleteClientButton' },
        { text: '', alias: 'actions', order: 'actions' },
      ],
      isLoadingAddClient: false,
      modalAddClientTitle: 'Подключение клиента',
      showAddClientModal: false,
      modalForm: {
        customerId: null,
      },
      initialModalForm: {
        customerId: null,
      },
    };
  },

  computed: {
    ...mapState({
      customers: (state) => state.common.customers,
      currentAccount: (state) => state.editableList.item,
    }),

    id() {
      return +this.$route.params.id;
    },

    userLogin() {
      return this.currentAccount?.data?.login;
    },

    breadcrumbs() {
      return [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Аккаунты',
          route: { name: 'Accounts' },
        },
        {
          text: `Редактирование аккаунта ${this.id}`,
          route: { name: 'AccountEdit', params: { id: this.id } },
        },
        {
          text: `Связанные клиенты`,
        },
      ];
    },

    isEqualAddClient() {
      return this.lodash.isEqual(this.initialModalForm, this.modalForm);
    },

    modalFields() {
      return [
        {
          type: 'select',
          key: 'customerId',
          label: 'Клиент',
          list: this.customers.data,
        },
      ];
    },

    isLoadingPage() {
      return this.customers.isLoading;
    },
  },

  created() {
    if (!this.id) {
      return this.$router.push({ name: 'Accounts' })
    }
    this.getItem({ name: 'Accounts', id: this.id })
    this.getCommonList({ name: 'Customers' })
  },

  methods: {
    ...mapActions({
      getCommonList: 'common/getCommonList',
      getItem: 'editableList/getItem',
    }),

    onEdit(id) {
      this.$router.push({
        name: 'CustomerEdit',
        params: { id },
      });
    },

    validateForm(form, mandatoryFields) {
      const emptyField = mandatoryFields.filter((item) => {
        return !form[item.key];
      });
      let message = null;
      if (emptyField.length) {
        message = `Не заполнены обязательные поля: ${emptyField
          .map((item) => item.label)
          .join(', ')}`;
      }
      return message;
    },

    onOpenAddClientModal() {
      this.showAddClientModal = true;
      this.modalForm = Object.assign({}, this.initialModalForm);
    },

    onCancelAddClient() {
      this.showAddClientModal = false;
      this.modalForm = Object.assign({}, this.initialModalForm);
    },

    onConfirmAddClient() {
      const mandatoryFields = [
        { key: 'customerId', label: 'Клиент' },
      ];
      const header = this.validateForm(this.modalForm, mandatoryFields);
      if (header) {
        this.$notify({
          header,
          type: 'error',
          timer: 5000,
        });
        return;
      }

      this.isLoadingAddClient = true;
      customersApi
        .attachAccount({
          ...this.modalForm,
          userLogin: this.userLogin,
        })
        .then((result) => {
          if (result.data.isSucceed) {
            this.$notify({
              header: 'Клиент успешно подключен!',
              type: 'success',
              timer: 5000,
            });
            this.$refs.editableList.fetchList();
          }
          this.showAddClientModal = false;
        })
        .finally(() => (this.isLoadingAddClient = false));
    },

    onOpenDeleteClientModal(id) {
      this.$root
        .$confirmModal({
          message: 'Отключить клиента?',
          leftButtonText: 'Да',
          rightButtonText: 'Нет',
        })
        .then((response) => {
          if (response) {
            customersApi
              .detachAccount({
                customerId: id,
                accountId: this.id,
              })
              .then((result) => {
                if (result.data.isSucceed) {
                  this.$notify({
                    header: 'Клиент успешно отключен!',
                    type: 'success',
                    timer: 5000,
                  });
                  this.$refs.editableList.fetchList();
                }
              });
          }
        });
    },
  },
};
</script>

<style lang="scss">
.add-client-button {
  width: auto;
}

.remove-client-button {
  width: auto;
  word-break: normal;
}
</style>
